<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-card>
            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Diurno / Nocturno
                  </h6>
                  <v-select
                    v-model="conservacion.tipoFormulario"
                    :disabled="editable"
                    label="nombre"
                    :options="jornada"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Zona
                  </h6>
                  <v-select
                    v-model="conservacion.zona"
                    :disabled="editable"
                    label="nombre"
                    :options="zonas"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Identificación / Poste
                  </h6>
                  <b-form-input
                    v-model="conservacion.posteReferencia"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Selecciona el Trabajo Realizado
                  </h6>
                  <v-select
                    v-model="trabajoRealizado"
                    :disabled="editable"
                    label="nombre"
                    :options="conservacionTrabajosRealizados"
                    @input="mostrarSeleccionado"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="trabajoRealizado && trabajoRealizado.id === 2">
              <b-col
                cols="12"
                sm="4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Código Anterior
                  </h6>
                  <b-form-input
                    v-model="conservacion.codigoAnterior"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Código Nuevo
                  </h6>
                  <b-form-input
                    v-model="conservacion.codigoNuevo"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Potencia
                  </h6>
                  <b-form-input
                    v-model="conservacion.potencia"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="trabajoRealizado && trabajoRealizado.id === 3">
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group>
                  <b-form-group>
                    <h6 class="text-primary">
                      Brazo Retirado
                    </h6>
                    <v-select
                      v-model="conservacion.brazoRetirado"
                      :disabled="editable"
                      label="nombre"
                      :options="medidaBrazo"
                    />
                  </b-form-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Brazo Instalado
                  </h6>
                  <v-select
                    v-model="conservacion.brazoInstalado"
                    :disabled="editable"
                    label="nombre"
                    :options="medidaBrazo"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="trabajoRealizado && trabajoRealizado.id === 8">
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Metros de Poda
                  </h6>
                  <b-form-input
                    v-model="conservacion.metrosPoda"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Observaciones
                  </h6>
                  <b-form-textarea
                    v-model="conservacion.observaciones"
                    :disabled="editable"
                    rows="2"
                    no-resize
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <!-- BOTONES -->
              <b-col
                v-if="editable"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="changeEditable"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Editar</span>
                </b-button>
              </b-col>
              <b-col
                v-if="!editable"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="guardarConservacion"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Guardar</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="cancel"
                >
                  <feather-icon
                    icon="SlashIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col cols="12">
                <h3
                  style="display: flex; justify-content: center;"
                  class="text-primary"
                >
                  Fotografías de la Conservación
                </h3>
                <b-carousel
                  id="carousel-caption"
                  controls
                  indicators
                  :interval="6000"
                >
                  <b-carousel-slide
                    class="card-img-overlay"
                    :img-src="'https://armadaled.org/wp-content/uploads/2020/03/autopista-iluminacion-led-500x445.jpg'"
                    style="height: 400px; width: 420px"
                  />
                  <b-carousel-slide
                    class="card-img-overlay"
                    :img-src="'https://www.mriu.com.mx/wp-content/uploads/2018/03/Poste-San-Luis-en-calle-vista-lateral.jpg'"
                    style="height: 400px; width: 420px"
                  />
                  <b-carousel-slide
                    class="card-img-overlay"
                    :img-src="'https://postes-de-acero.com/img/postes_de_acero_para_alumbrado_publico.jpg'"
                    style="height: 400px; width: 420px"
                  />
                  <b-carousel-slide
                    class="card-img-overlay"
                    :img-src="'https://http2.mlstatic.com/D_NQ_NP_918784-MLM45628190939_042021-W.jpg'"
                    style="height: 400px; width: 420px"
                  />
                  <b-carousel-slide
                    class="card-img-overlay"
                    :img-src="'https://www.elsoldemorelia.com.mx/local/z12prw-postes-de-luz/ALTERNATES/LANDSCAPE_1140/Postes%20de%20luz'"
                  />
                </b-carousel>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BCard, BButton, VBModal, BFormTextarea, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getConservacion, getListadoConservacion } from '@/utils/mantenimiento/conservacion/conservacionUtils'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      conservacion: {},
      id: router.currentRoute.params.id,
      conservacionTrabajosRealizados: [],
      trabajoRealizado: null,
      jornada: [],
      zonas: [],
      medidaBrazo: [],
      tiposFalla: [],
      seguimiento: [],
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
    }
  },
  async created() {
    this.conservacionTrabajosRealizados = getListadoConservacion(1)
    this.zonas = getListadoConservacion(2)
    this.medidaBrazo = getListadoConservacion(3)
    this.jornada = getListadoConservacion(4)
    this.conservacion = await getConservacion(this.id)
    console.log('conservacion', this.conservacion)
    // eslint-disable-next-line prefer-destructuring
    this.trabajoRealizado = this.conservacion.trabajosRealizados[0]
  },
  methods: {
    mostrarSeleccionado() {
      console.log(this.conservacion.trabajoRealizado)
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    async cancel() {
      this.editable = true
    },
    async guardarConservacion() {
      console.log(this.conservacion)
      if (this.conservacion.fotoPanoramicaAntes === '') {
        this.mensajeError('Debe Ingresar una Fotografía Panorámica del Antes!')
        return
      }
      if (this.conservacion.fotoPanoramicaDespues === '') {
        this.mensajeError('Debe Ingresar una Fotografía Panorámica del Después!')
        return
      }
      this.show = true
      this.mostrarMensajeSucces()
    },
    fotografiaPanoramica(file) {
      this.fallaBt.fotoPanoramica = file
    },
    fotografiaSolucion(file) {
      this.fallaBt.fotoSolucion = file
    },
    fotografiaSeguimiento(file) {
      this.fallaBt.fotoSeguimiento = file
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
